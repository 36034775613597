exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-aritzia-js": () => import("./../../../src/pages/projects/aritzia.js" /* webpackChunkName: "component---src-pages-projects-aritzia-js" */),
  "component---src-pages-projects-hardsurface-js": () => import("./../../../src/pages/projects/hardsurface.js" /* webpackChunkName: "component---src-pages-projects-hardsurface-js" */),
  "component---src-pages-projects-landingb-js": () => import("./../../../src/pages/projects/landingb.js" /* webpackChunkName: "component---src-pages-projects-landingb-js" */),
  "component---src-pages-projects-landingp-js": () => import("./../../../src/pages/projects/landingp.js" /* webpackChunkName: "component---src-pages-projects-landingp-js" */),
  "component---src-pages-projects-project-js": () => import("./../../../src/pages/projects/project.js" /* webpackChunkName: "component---src-pages-projects-project-js" */),
  "component---src-pages-projects-ring-js": () => import("./../../../src/pages/projects/ring.js" /* webpackChunkName: "component---src-pages-projects-ring-js" */),
  "component---src-pages-projects-teika-js": () => import("./../../../src/pages/projects/teika.js" /* webpackChunkName: "component---src-pages-projects-teika-js" */),
  "component---src-pages-projects-tome-js": () => import("./../../../src/pages/projects/tome.js" /* webpackChunkName: "component---src-pages-projects-tome-js" */)
}

